import { Injectable } from "@angular/core";
import { RESTService } from "../../../shared/services/rest.service";
import { Observable } from "rxjs";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class SuplierService {
  constructor(private rest: RESTService) {}

  createSuplier(entity: any): Observable<any> {
    return this.rest.post(`/suppliers`, entity);
  }

  getAllSuppliers(): Observable<any> {
    return this.rest.get(`/suppliers`);
  }

  createContactDebtor(entity): Observable<any> {
    return this.rest.post(`/contacts`, entity);
  }

  createBankAccount(entity): Observable<any> {
    return this.rest.post(`/suppliers/${entity.debtor}/bank-accounts`, entity);
  }

  byRuc(id) {
    return this.rest.get(`/suppliers/${id}`);
  }

  getAllContacts(id) {
    return this.rest.get(`/suppliers/${id}/contacts`);
  }

  getAllContactsByClient(clientId: string, id: string) {
    return this.rest.get(`/suppliers/${id}/contacts?clientId=${clientId}`);
  }

  getBanks(id) {
    return this.rest.get(`/suppliers/${id}/bank-accounts`);
  }

  getBanksAdmin(id, clientID: string): Observable<any> {
    return this.rest.get(`/suppliers/${id}/bank-accounts?user=${clientID}`);
  }

  updateReadyContacts(companyRuc, contactsReady): Observable<any> {
    return this.rest.patch(`/contacts`, { companyRuc, contactsReady });
  }
}
