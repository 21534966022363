<div class="container">
    <!--
    <div class="row">
        <div class="payer-description">
            <div *ngIf="false" class="payer-and-currency row">
                <div class="payer-container">
                    <app-payer-description [title]="'Pagador'" [payer]="opportunity.client" [currency]="opportunity.type"
                        [description]="opportunity.description"></app-payer-description>
                </div>
            </div>
        </div>
    </div>
    -->
    <div class="bank-and-currency row">
        <div class="bank-container">
            <p class="tiny tiny-title">Empresa pagadora</p>
            <p class="bold">{{payload.debtorCompanyName || payload.debtor.companyName | uppercase}}</p>
        </div>

    </div>
    <div *ngIf="payload?.evaluation" class="risk-row">
        <div>
            <div class="icon-row clickable mw-100" (click)="ratingDisclaimerOpen=!ratingDisclaimerOpen">
                <lib-rating [rating]="payload?.evaluation?.rating"></lib-rating>
                <div class="icon-row-inner rating-question">
                    <span>{{ratingDisclaimerOpen ? 'Ocultar' : '¿Qué es el Rating?'}}</span>
                    <img [className]="ratingDisclaimerOpen ? 'expand-less' : 'expand-more'">
                </div>
            </div>

            <div class="rating-disclaimer-transition"
                [ngClass]="(ratingDisclaimerOpen === true) ? 'rating-disclaimer-appear' : 'rating-close'">
                <div class="rating-disclaimer">
                    <p class="p-description">Esta clasificación de riesgo es solo una herramienta orientativa calculada
                        según la
                        Metodología de
                        Finsmart. No es una asesoría, respaldo ni recomendación de inversión.</p>
                    <div class="icon-row link">
                        <div>
                            <a class="hyperlink" target="_blank" #more
                                href="https://storage.googleapis.com/finsmart-websitefiles/legal/clasificacion-empresas.pdf">Quiero
                                saber
                                más</a>
                        </div>
                        <img class="svg-blue" (click)="more.click()"
                            src="../../../../../../assets/icons/arrow_forward-24px.svg">
                    </div>
                </div>
            </div>

        </div>
        <div class="icon-row icon-gap">
            <div class="icon-box">
                <img src="../../../../../../assets/icons/business.svg">
            </div>
            <div class="icon-text">
                <p class="p-title">Sector</p>
                <p class="p-value">{{payload?.evaluation?.debtor?.sector | uppercase}}</p>
            </div>
        </div>
        <div class="icon-row icon-gap activity">
            <div class="icon-box">
                <img src="../../../../../../assets/icons/store.svg">
            </div>
            <div class="icon-text">
                <p class="p-title">Actividad</p>
                <p class="p-value">{{payload?.evaluation?.debtor?.activity | uppercase }}</p>
            </div>
        </div>
    </div>
    <!-- <div class="debtor-stats" *ngIf="debtorStats">
        <div class="bank-and-currency row">
            <div class="bank-container">
                <p class="tiny tiny-title">Facturas finalizadas</p>
                <p class="">{{debtorStats.finalizedInvoicesCount}}</p>
            </div>
            <div class="bank-container">
                <p class="tiny tiny-title">Facturas en curso</p>
                <p class="">{{debtorStats.invoicesAwaitingCollectionCount}}</p>
            </div>
        </div>
        <div class="bank-and-currency row">
            <div class="bank-container">
                <p class="tiny tiny-title">En mora (> 8 días)</p>
                <p class="">{{debtorStats.delayedInvoicesCount}}</p>
            </div>
            <div class="bank-container">
                <p class="tiny tiny-title">Promedio mora con Finsmart</p>
                <p class="">{{debtorStats.averageDelayOfFinalizedInvoices}}</p>
            </div>
        </div>
    </div> -->
    <!-- <div class="bank-container type-operation">
        <div class="bank-and-currency row">
            <app-operation-invoice [isConfirming]="payload.isConfirming"></app-operation-invoice>
        </div>
    </div> -->
    <!--div class="risk-row">
        <div class="icon-row clickable" style="width: 20%;" (click)="ratingDisclaimerOpen=!ratingDisclaimerOpen">
            <span class="rating-box">{{payload?.evaluation?.rating}}</span>
            <div class="icon-row-inner rating-question">
                <span>{{ratingDisclaimerOpen ? 'Ocultar' : '¿Qué es Rating?'}}</span>
                <img [className]="ratingDisclaimerOpen ? 'expand-less' : 'expand-more'">
            </div>
        </div>
        <div class="icon-row icon-gap" style="width: 22%;">
            <div class="icon-box">
                <img src="../../../../../../assets/icons/business.svg">
            </div>
            <div class="icon-text">
                <p>Sector</p>
                <p>{{payload?.debtor?.sector | uppercase}}</p>
            </div>
        </div>
        <div class="icon-row icon-gap" style="width: 38%">
            <div class="icon-box">
                <img src="../../../../../../assets/icons/store.svg">
            </div>
            <div class="icon-text">
                <p>Actividad</p>
                <p>{{payload?.debtor?.activity | uppercase}}</p>
            </div>
        </div>
    </div>
    <div class="rating-disclaimer" *ngIf="ratingDisclaimerOpen">
        <p>Esta clasificación de riesgo es solo una herramienta orientativa calculada según la Metodología de
            Finsmart. No es una asesoría, respaldo ni recomendación de inversión.</p>
        <div class="icon-row link">
            <div>
                <a class="link" target="_blank" #more href="https://storage.googleapis.com/finsmart-websitefiles/legal/Te%CC%81rminos%20y%20Condiciones%20v3.1.docx.pdf">Quiero
                    saber
                    más</a>
            </div>
            <img class="svg-blue" (click)="more.click()"
                src="../../../../../../assets/icons/arrow_forward-24px.svg">
        </div>
    </div-->
    <div class="row bar-row">
        <ng-container *ngIf="mode === 'actual'">
            <ng-container *ngIf="investmentsSum$ as investmentsSum">
                <app-invoices-progress-bar [status]="payload.status" [amount]="investmentsSum"
                    [currency]="payload.currency"
                    [roi]="this.invoiceService.calculateROI(investmentsSum, payload.tem, payload.minimumDuration)"
                    [percentage]="this.invoiceService.datesPercentage(payload.advancePaymentDate, payload.paymentDate)"
                    [dates]="payload.minimumDuration" [toBeCollectedIn]="payload.toBeCollectedIn"
                    [totalAmount]="payload.advanceAmount" [moraDays]="payload.moraDays" [type]="mode">
                </app-invoices-progress-bar>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="mode === 'historic'">
            <ng-container *ngIf="investmentsSum$ as investmentsSum">
                <app-invoices-progress-bar [status]="payload.status" [amount]="investmentsSum"
                    [currency]="payload.currency"
                    [roi]="this.invoiceService.calculateROI(investmentsSum, payload.tem, payload.actualDuration)"
                    [percentage]="this.invoiceService.investmentPercentage(payload.advanceAmount, investmentsSum)"
                    [dates]="invoiceService.calculateCollectedDays(payload.paymentDate,payload.actualPaymentDate )"
                    [totalAmount]="payload.advanceAmount" [type]="mode">
                </app-invoices-progress-bar>
            </ng-container>
        </ng-container>

    </div>

    <!-- <ng-container *ngIf="mode === 'actual'">
        <div>
            <app-invoice-duration [mode]="mode" [paymentDate]="payload.paymentDate"
                [advancePaymentDate]="payload.advancePaymentDate" [minDurationDays]="payload.minimumDuration"
                [tem]="payload.tem" [tea]="payload.tea"></app-invoice-duration>
        </div>
    </ng-container>
    <ng-container *ngIf="mode === 'historic'">
        <div>
            <app-invoice-duration [mode]="mode" [paymentDate]="payload.actualPaymentDate"
                [advancePaymentDate]="payload.advancePaymentDate" [minDurationDays]="payload.actualDuration"
                [tem]="payload.tem" [tea]="payload.tea"></app-invoice-duration>
        </div>
    </ng-container> -->

    <!-- <div class="row observations" *ngIf="false">
        <h3>Observaciones</h3>
        <p>Se espera que la factura sea cobrada en la fecha proyectada o dentro de los ochos días posteriores</p>
    </div> -->
    <h6 class="important-info">Información importante</h6>

    <!-- <div class="invoice-detail"> -->

    <lib-tab-invest-in-invoice [riskInfo]="payload?.evaluation?.criterias?.length > 0"
        [companyInfo]="payload?.companyDetail" (selectedTab)="updateTabSelected($event)" mode="investment">
        <div class="invoice-announcement">
            <div *ngIf="payload?.announcements?.length > 0; else empty_announcements">
                <app-chip [imgIcon]="'exclamation.svg'"
                    [content]="'Esta información es confidencial y no debe ser revelada a terceros ya que puede afectar la cobranza de la factura.'"
                    [closedIcon]="false"></app-chip>

                <div class="info-chat-left">
                    <lib-icon color="black" icon="annoucement" size="m"></lib-icon>
                    <p>
                        Actualizaciones de cobranza ({{payload?.announcements?.length }})
                    </p>
                </div>
                <ng-container *ngFor="let item of payload?.announcements | sort:'createdAt':'desc';  let last = last ">
                    <lib-announcement-timeline (sendReply)="updateReply($event)" (sendMessage)="updateReply($event)"
                        [isFirst]="last" [announcement]="item" [message]="(item?.message) | safeHtml"
                        (openFile)="openViewer($event)">
                    </lib-announcement-timeline>
                </ng-container>

                <div class="info-chat">
                    <lib-icon icon="waving_hand" size="m"></lib-icon>
                    <p>
                        Si tienes consultas sobre esta operación, <span class="hyperlink"
                            (click)="openIntercom()">escríbenos
                            al chat</span>.
                    </p>
                </div>

            </div>
        </div>
        <div class="invoice-detail-investment">
            <div class="boxes">
                <app-detail-box [label]="'Tasa anualizada'" [value]="payload?.tea + ' %'"></app-detail-box>
                <app-detail-box [label]="'Tasa mensual'" [value]="payload?.tem + ' %'"></app-detail-box>
                <app-detail-box [label]="'Fecha de inicio'" [value]="payload?.advancePaymentDate | formatDate2">
                </app-detail-box>
                <app-detail-box *ngIf="mode === 'historic'" [label]="'Cobro efectivo'"
                    [value]="payload?.actualPaymentDate | formatDate2">
                </app-detail-box>
                <app-detail-box *ngIf="mode !== 'historic'" [label]="'Cobro proyectado'"
                    [value]="payload?.paymentDate | formatDate2">
                </app-detail-box>
                <app-detail-box [label]="'Códigos ('+ payload?.physicalInvoices?.length + ')'"
                    [top]="payload?.physicalInvoices?.length > 1 ? 18 : 25" [isCustomValue]="true"
                    [longValue]="payload?.physicalInvoices?.length > 1 ? codes : null">
                    <!-- [value]="payload?.physicalInvoices?.length > 1 ? payload?.physicalInvoices[0]?.code + '\n...' : payload?.physicalInvoices[0]?.code" -->
                    <div class="box-value">
                        {{payload?.physicalInvoices[0]?.code}}
                        <span *ngIf="payload?.physicalInvoices?.length > 1" style="font-weight: bold;">
                            <br>
                            ...
                        </span>
                    </div>
                </app-detail-box>
                <app-detail-box [label]="'Tipo de operación'" [isCustomValue]="true">
                    <div class="box-value">
                        <lib-operation-type [operationType]="payload?.isConfirming"></lib-operation-type>
                    </div>
                </app-detail-box>
            </div>
        </div>
        <div class="finsmart-history-investment">
            <ng-container>
                <div class="boxes">
                    <app-detail-box [label]="'Facturas finalizadas'" [value]="debtorStats?.finalizedInvoicesCount">
                    </app-detail-box>
                    <app-detail-box [label]="'Facturas en curso'"
                        [value]="debtorStats?.invoicesAwaitingCollectionCount">
                    </app-detail-box>
                    <app-detail-box [label]="'En mora (> 8 días)'" [value]="debtorStats?.delayedInvoicesCount">
                    </app-detail-box>
                    <app-detail-box [label]="'Prom. mora con FS'"
                        [value]="debtorStats?.averageDelayOfFinalizedInvoices">
                    </app-detail-box>
                </div>
            </ng-container>
        </div>
        <div class="company-detail-investment">
            <p class="company-detail-content">{{payload?.companyDetail}}</p>
        </div>
        <div class="risk-detail-investment">
            <ng-container>
                <div class="boxes">
                    <app-detail-box [label]="'Tipo de sociedad'" [value]="getRiskData(payload, 'companyName')">
                    </app-detail-box>
                    <app-detail-box [label]="'Fecha de creación'" [value]="getRiskData(payload, 'createdAt', 'others')"
                        [others]="getRiskData(payload, 'createdAt') ? getRiskData(payload, 'createdAt') + ' años' : ''"
                        [tooltip]="'La antigüedad de la empresa indica la experiencia que tiene en el negocio.'"
                        [top]="'18'">
                    </app-detail-box>
                    <app-detail-box [label]="'Padrón SUNAT'"
                        [value]="(getRiskData(payload, 'regimeType') == '0') ? '---'  : (getRiskData(payload, 'regimeType')  | capitalizeOnlyFirstLetter)"
                        [tooltip]="'Una empresa registrada en un padrón de SUNAT, como Agente de Percepción, Agente de Retención o Buen Contribuyente, cumple con sus obligaciones tributarias.'">
                    </app-detail-box>
                    <app-detail-box [label]="'Nro de trabajadores'" [value]="getRiskData(payload, 'employeeCount')"
                        [tooltip]="'Permite proyectar un flujo de efectivo mínimo para afrontar el pago de su planilla y estimar el tamaño de la empresa.'">
                    </app-detail-box>
                    <app-detail-box [label]="'Deuda directa'" [value]="getRiskData(payload, 'directDebt')"
                        [tooltip]="'Indica el nivel de confianza de bancos y financieras y la capacidad de recibir financiamiento.'">
                    </app-detail-box>
                    <app-detail-box [label]="'Deuda indirecta'" [value]="getRiskData(payload, 'indirectDebt')"
                        [tooltip]="'Indica qué tanto los bancos y financieras han garantizado el cumplimiento de las obligaciones de la empresa (cartas fianzas, cartas de crédito documentario de importación, etc).'">
                    </app-detail-box>
                    <app-detail-box [label]="'Garantías hipotecarias'"
                        [value]="getRiskData(payload, 'mortgageGuarantees')"
                        [tooltip]="'Indica los activos que respaldan las obligaciones financieras de la empresa. Permite estimar su nivel patrimonial o el de sus garantes.'">
                    </app-detail-box>
                    <app-detail-box [label]="'Protestos sin aclarar'" [value]="getRiskData(payload, 'unclearProtests')"
                        [tooltip]="'Permite conocer el comportamiento de la empresa frente a las obligaciones que ha asumido con títulos valores. Se observan las deudas impagas registradas durante los últimos 24 meses. El monto es relevante cuando representa un porcentaje alto de la deuda directa.'">
                    </app-detail-box>
                    <app-detail-box [label]="'Importaciones'" [value]="getAdditionalInfo(payload, 'importsValue')"
                        [others]="getAdditionalInfo(payload, 'importsOpsValue') ? getAdditionalInfo(payload, 'importsOpsValue') + ' operaciones': ''"
                        [tooltip]="'Indica el nivel de ventas de la empresa en el 2020 y que sus proveedores son del exterior.'"
                        [top]="'18'">
                    </app-detail-box>
                    <app-detail-box [label]="'Exportaciones'" [value]="getAdditionalInfo(payload, 'exportsValue')"
                        [others]="getAdditionalInfo(payload, 'exportsOpsValue') ? getAdditionalInfo(payload, 'exportsOpsValue') + ' operaciones': ''"
                        [tooltip]="'Indica el nivel de ventas de la empresa en el 2020 y cuánto de su mercado y cartera son del exterior.'"
                        [top]="'18'">
                    </app-detail-box>
                </div>

                <div class="icon-row2">
                    <img src="../../../../../../assets/icons/info.svg">
                    <span class="p-height">Fecha de consulta: {{payload?.evaluation?.createdAt | date: 'dd/MM/yyyy' }}.
                        Fuentes: Central
                        de Riesgos de la SBS, centrales privadas de
                        riesgos, Registro Nacional de Protestos y Moras de la Cámara de Comercio de Lima, SUNAT, entre
                        otras fuentes de información. <a class="link" target="_blank"
                            href="https://storage.googleapis.com/finsmart-websitefiles/legal/clasificacion-empresas.pdf">Más
                            información aquí</a>.</span>
                </div>
            </ng-container>
        </div>
    </lib-tab-invest-in-invoice>
</div>
<ng-template #empty_announcements>
    <div class="no-transactions-found ">
        <lib-icon icon="waving_hand" size="xl"></lib-icon>
        <p class="disclaimer">No hay actualizaciones registradas.</p>
    </div>
    <p class="info-container">
        Si tienes consultas sobre esta operación, <span class="hyperlink" (click)="openIntercom()">escríbenos
            al chat</span>.
    </p>

</ng-template>