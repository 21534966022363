import { Injectable } from "@angular/core";
import { RESTService } from "src/app/shared/services/rest.service";
import { Observable, of, Subject } from "rxjs";
import * as _ from "lodash";
import { HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { formatFilter } from "src/app/shared/util/api.util";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  private subject = new Subject<any>();

  constructor(private rest: RESTService) {}

  get(skip, limit, filter) {
    delete filter.pagination;

    const filterHasValues = filter
      ? Object.values(filter).some((v) => {
          if (Array.isArray(v)) {
            return v.length > 0 ? true : false;
          } else {
            return !!v;
          }
        })
      : undefined;

    if (filterHasValues) {
      const filtersArray = [];
      const rolesFilter = formatFilter(filter.roles, "role", "in");
      const statusFilter = formatFilter(filter.statuses, "status", "in");
      const userFilter = formatFilter(filter.user, "_id", "eq");
      const showBlockedFilter = formatFilter(
        filter.showBlocked,
        "isBlocked",
        "eq"
      );
      const showNotSyncedOnlyFilter = formatFilter(
        false,
        "isPipedriveSynced",
        "eq"
      );

      if (statusFilter) filtersArray.push(statusFilter);
      if (rolesFilter) filtersArray.push(rolesFilter);
      if (showBlockedFilter) filtersArray.push(showBlockedFilter);
      if (filter.showNotSyncedOnly) filtersArray.push(showNotSyncedOnlyFilter);
      if (userFilter) filtersArray.push(userFilter);

      return this.rest.get(
        `/users?skip=${skip}&limit=${limit}&filter=${filtersArray.join(",")}`
      );
    } else {
      return this.rest.get(`/users?skip=${skip}&limit=${limit}`);
    }
  }

  getUserDocument(propertyKey, itemId): Observable<any> {
    let keyKebabCase;

    if (propertyKey === "idFrontPicture") {
      keyKebabCase = "id-front-picture";
    } else if (propertyKey === "idBackPicture") {
      keyKebabCase = "id-back-picture";
    } else {
      keyKebabCase = propertyKey;
    }

    return this.rest.get(`/users/${itemId}/${keyKebabCase}`);
  }

  byId(id) {
    return this.rest.get(`/users/${id}`);
  }

  searchUsers(query): Observable<any> {
    if (query) {
      const encodedQuery = query.replace(new RegExp("\\+", "g"), "%2b");
      return this.rest.get(`/users-accounts/search?term=${encodedQuery}`);
    } else {
      return of([]);
    }
  }
  searchInvestors(query): Observable<any> {
    if (query) {
      const encodedQuery = query
        .toString()
        .replace(new RegExp("\\+", "g"), "%2b");
      return this.rest.get(`/investors/search?term=${encodedQuery}`);
    } else {
      return of([]);
    }
  }
  searchDebtors(query): Observable<any> {
    if (query) {
      // console.log("query")
      // console.log(query)
      const encodedQuery = query
        .toString()
        .replace(new RegExp("\\+", "g"), "%2b");
      //console.log(encodedQuery.toString())
      return this.rest.get(`/debtors/search?term=${encodedQuery}`);
    } else {
      return of([]);
    }
  }
  searchClients(query): Observable<any> {
    if (query) {
      // console.log("query-investor")
      // console.log(query)
      const encodedQuery = query
        .toString()
        .replace(new RegExp("\\+", "g"), "%2b");
      return this.rest.get(`/clients/search?term=${encodedQuery}`);
    } else {
      return of([]);
    }
  }
  getAll(): Observable<any> {
    return this.rest.get(`/users`);
  }
  getProfile(): Observable<any> {
    return this.rest.get(`/profiles`);
  }
  emailExists(email): Observable<any> {
    //Default encoder does not encode + sign https://medium.com/better-programming/how-to-fix-angular-httpclient-not-escaping-url-parameters-ddce3f9b8746
    //Will replace for now but we we could find a better solution in the future
    //let options = { params: new HttpParams({encoder: this.customHttpParamEncoder}).set('email', email) }

    //also once we solve this we will be able to use
    //params options instead of statically assigning it

    let encodedEmail = email.replace(new RegExp("\\+", "g"), "%2b");

    return this.rest.head(`/users?email=${encodedEmail.trim().toLowerCase()}`);
  }

  documentExist(idType, idNumber): Observable<any> {
    return this.rest.head(`/users?idType=${idType}&idNumber=${idNumber}`);
  }

  emailDuplicate(email, emails): Observable<any> {
    console.log(email);
    console.log(emails);
    return new Observable<any>().pipe(map(() => false));
  }

  rucExists(ruc): Observable<any> {
    //Make it flexible in case we want to check for investors as well
    //By accepting ruc instead of companyRuc
    //Actually it's not going to work because investor RUC would be just ruc instead of companyRuc
    //Just get it done for now, registation requirements is unstable now anyway

    return this.rest.head(`/users?ruc=${ruc.trim().toLowerCase()}`);
  }
  currentPasswordCorrect(password): Observable<any> {
    return this.rest.head(`/users?password=${password}`);
  }
  getUserById(userId): Observable<any> {
    return this.rest.get(`/users/${userId}`);
  }
  confirmUserEmail(token, userId): Observable<any> {
    let header = {
      headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
    };

    return this.rest.patch(
      `/users/${userId}`,
      { status: "email verified" },
      header
    );
  }

  resendEmailConfirmation(token, userId): Observable<any> {
    let header = {
      headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
    };

    return this.rest.patch(
      `/users/${userId}`,
      {
        clientUrl2: `${environment.clientUrl}/${environment.emailConfirmationUri}`,
      },
      header
    );
  }

  resetUserPassword(token, password, userId): Observable<any> {
    let header = {
      headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
    };

    return this.rest.patch(`/users/${userId}`, { password }, header);
  }

  create(user): Observable<any> {
    const newUser = {
      ...user,
      clientUrl: `${environment.clientUrl}/${environment.emailConfirmationUri}`,
    };
    return this.rest.post(`/users`, newUser);
    //const data = _.pick(user, ['name', 'cci', 'currency', 'number']);
  }
  updateForUser(user): Observable<any> {
    let data = _.omit(user, "_id");
    const userId = user._id;

    return this.rest.patch(`/users/${userId}`, data);
  }
  updateOwn(data): Observable<any> {
    return this.rest.patch(`/profiles`, data);
  }

  saveProfile(user, mode): Observable<any> {
    // console.log(user);
    // console.log(mode);
    switch (mode) {
      case "editOwn":
        return this.updateOwn(user);
    }
  }
  saveUser(user, mode): Observable<any> {
    console.log(user);
    console.log(mode);
    let data = _.omit(user, "urlType");

    switch (mode) {
      case "create":
        return this.create(data);
      case "edit":
        return this.updateForUser(data);
    }
  }

  saveUserInformation(data): Observable<any> {
    console.log(data);
    const userId = data._id;
    return this.rest.patch(`/users/${userId}`, data);
  }

  resendConfirmation() {
    console.log("works");
  }

  sendClickEvent() {
    this.subject.next();
  }
  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  verifyNumber(data): Observable<any> {
    return this.rest.patch(`/profiles`, data);
  }

  validateRuc(ruc): Observable<any> {
    return this.rest.get(`/sunat/${ruc}`);
  }
}
