import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormService } from "src/app/shared/services/form.service";
import { GTMService } from "src/app/shared/services/gtm.service";
import { RucService } from "src/app/shared/services/ruc.service";
import { EmailValidator } from "src/app/shared/validators/email.validators";
import { MustBeTrue } from "src/app/shared/validators/must-be-true.validator";
import { NumbersOnlyValidator } from "src/app/shared/validators/numbers-only.validators";
import { PasswordCriteriaValidator } from "src/app/shared/validators/password-criteria.validator";
import { RequiredValidator } from "src/app/shared/validators/required.validator";
import { RucExactLengthValidator } from "src/app/shared/validators/ruc-exact-length.validator";
import { RUCValidlValidator } from "src/app/shared/validators/ruc.validator";
import { UniqueUserByRuclValidator } from "src/app/shared/validators/unique-user-by-ruc-validator";

@Component({
  selector: "app-signup-client-step",
  templateUrl: "./signup-client-step.component.html",
  styleUrls: ["./signup-client-step.component.scss"],
})
export class SignupClientStepComponent implements OnInit {
  @Output() previousStep = new EventEmitter();
  @Output() nextStep = new EventEmitter();
  companyInfo;

  form: FormGroup;
  channels;
  iconName;
  hasIcon = false;

  termConditionContent =
    "No quiero <span class='bold'>recibir comunicaciones</span> con fines comerciales.";

  constructor(
    private formBuilder: FormBuilder,
    private uniqueUserByRuclValidator: UniqueUserByRuclValidator,
    public gtmService: GTMService,
    private rucService: RucService,
    private formService: FormService,
    private rucValidator: RUCValidlValidator
  ) {}

  ngOnInit(): void {
    this.channels = this.formService.getChannels();
    this.form = this.formBuilder.group({
      companyRuc: [
        "",
        [RequiredValidator, NumbersOnlyValidator, RucExactLengthValidator(11)],
        [
          this.uniqueUserByRuclValidator.isUserRucUnique(),
          this.rucValidator.isUserRucValid(),
        ],
      ],
      channel: ["", [Validators.required]],
      isNewsletter: [""],
      companyAddress: [""],
      companyName: [""],
      companyDepartment: [""],
    });
    this.form.get("companyRuc")?.valueChanges.subscribe(() => {
      this.setIcon();
    });
  }

  backward() {
    let event = {
      event: "[Platform][Next][Button]",
      _inputName: `Clicked Atrás In Step 3`,
      _inputCategory: `Signup Client / Step 3`,
    };
    this.sendEvent(event);
    this.previousStep.emit();
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    console.log(this.form.value.isNewsletter);
    this.form.value.isNewsletter = !this.form.value.isNewsletter;
    console.log(this.form.value.isNewsletter);

    if (this.form.valid) {
      // console.log(this.companyInfo);
      this.nextStep.emit(this.form.value);
    } else {
      let event = {
        event: "[Platform][Next][Button]",
        _inputName: `Clicked Crear Mi Cuenta In Step 3 Error`,
        _inputCategory: `Signup Client / Step 3`,
        _inputError: this.formService.getFormValidationErrors(this.form),
      };
      this.sendEvent(event);
    }
  }

  sendEvent(event) {
    this.gtmService.newEvent(event);
  }

  get companyRuc() {
    return this.form.get("companyRuc");
  }

  setIcon() {
    if (this.companyRuc?.value.length === 11) {
      setTimeout(() => {
        if (this.companyRuc?.errors?.isRucValid) {
          this.iconName = "alert-icon";
          this.hasIcon = true;
        } else if (!this.companyRuc?.errors) {
          this.iconName = "success-icon";
          this.hasIcon = true;
        }
      }, 1700);
    } else {
      this.iconName = "";
      this.hasIcon = false;
    }
  }
}
