<!--
<a
    class="button button-blue"
    *ngIf="
      evaluation?.status === 'pending' &&
      !evaluation?.hasOwnProperty('isReadyForEvaluation') &&
      !evaluation?.isReadyForEvaluation &&
      loggedInUserRole == 'risk analyst'
    "
    (click)="complete(evaluation)"
    >Completar</a
  >

   <a
    class="button button-blue"
    *ngIf="
      evaluation?.status === 'pending' &&
      evaluation?.hasOwnProperty('isReadyForEvaluation') &&
      evaluation?.isReadyForEvaluation &&
      loggedInUserRole == 'risk analyst'
    "
    (click)="evaluate(evaluation)"
    >Evaluar</a
  >
  -->

<div class="title">
  <div class="title__left">
    <app-page-title title="Evaluación"> </app-page-title>
    <lib-operation-type
      *ngIf="
        evaluation?.operationType === 'factoring' ||
        evaluation?.operationType === 'confirming'
      "
      [operationType]="evaluation.operationType === 'confirming'"
    ></lib-operation-type>
  </div>
  <div class="t-right">
    <span *ngIf="evaluation" class="evaluation-date"
      >{{
        evaluation.status === "pending"
          ? "Fecha de creación de
            evaluación"
          : "Fecha de dictamen"
      }}:
      {{
        evaluation.status === "pending"
          ? (evaluation.createdAt | date : "dd/MM/yyyy HH:mm:ss")
          : evaluation.evaluationFinishedAt
          ? (evaluation.evaluationFinishedAt | date : "dd/MM/yyyy HH:mm:ss")
          : (evaluation.createdAt | date : "dd/MM/yyyy HH:mm:ss")
      }}
    </span>
    <div class="actions">
      <div [ngClass]="evaluation?.invoiceNetAmount ? 'one-line-flex' : ''">
        <div class="tooltip-container-not-dots">
          <app-account-manager-icon
            [accountName]="getAccountName(evaluation)"
            [isActive]="true"
            [isCartera]="
              evaluation?.createdBy?.role !== 'risk analyst' &&
              !evaluation?.debtorLineProposalV4
            "
          >
          </app-account-manager-icon>
          <span
            *ngIf="
              evaluation?.createdBy?.role === 'risk analyst' ||
              evaluation?.debtorLineProposalV4
            "
            class="tooltiptext-bottom"
            >{{ getAccountName(evaluation) | capitalizeFirstLetters }}</span
          >
          <span
            *ngIf="
              evaluation?.createdBy?.role !== 'risk analyst' &&
              !evaluation?.debtorLineProposalV4
            "
            class="tooltiptext-bottom"
            >Cartera</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <div *ngIf="evaluation">
    <app-template-user-detail
      [role]="loggedInUserRole"
      (deleteDocument)="reFetchEvaluation()"
      (documentDialog)="openDialogDocument($event)"
      [evaluation]="evaluation"
    ></app-template-user-detail>
  </div>
</div>
<div class="code-table matrix-table">
  <table>
    <tr>
      <th>Ítem</th>
      <th>Otros</th>
      <th>Dato</th>
      <th>Valor</th>
    </tr>
    <ng-container *ngIf="evaluation">
      <tr
        *ngIf="
          evaluation.criterias | filterBy : 'item' : 'companyName' as criteria
        "
      >
        <td>Tipo de sociedad</td>
        <td></td>
        <td>
          {{ criteria[0]?.data ? (criteria[0]?.data | uppercase) : "---" }}
        </td>
        <td *ngIf="evaluation.matrixVersion === '4'"></td>
        <td *ngIf="evaluation.matrixVersion !== '4'" class="t-black">
          {{
            criteria[0]?.value
              ? (criteria[0]?.value | capitalizeOrSpacing) + "%"
              : ""
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias | filterBy : 'item' : 'createdAt' as criteria
        "
      >
        <td>Fecha de creación</td>
        <td>{{ criteria[0]?.others }}</td>
        <td>
          {{
            criteria[0]?.data
              ? (criteria[0]?.data | capitalizeOrSpacing)
              : "---"
          }}
        </td>
        <td class="t-black">
          {{
            criteria[0].value
              ? (criteria[0].value | capitalizeOrSpacing) + "%"
              : "0%"
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias | filterBy : 'item' : 'regimeType' as criteria
        "
      >
        <td>Padrón</td>
        <td></td>
        <td>
          {{
            criteria[0]?.data
              ? (criteria[0]?.data | capitalizeOrSpacing)
              : "---"
          }}
        </td>
        <td *ngIf="evaluation.matrixVersion === '4'"></td>
        <td *ngIf="evaluation.matrixVersion !== '4'" class="t-black">
          {{
            criteria[0]?.value
              ? (criteria[0]?.value | capitalizeOrSpacing) + "%"
              : ""
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias
            | filterBy : 'item' : 'billingFiveYears' as criteria
        "
      >
        <td>Facturación</td>
        <td *ngIf="evaluation.matrixVersion === '4'">
          {{ criteria[0]?.others }}
        </td>
        <td *ngIf="evaluation.matrixVersion !== '4'">{{ fiveYearsAgo }}</td>
        <td>
          {{
            criteria[0]?.data
              ? (criteria[0]?.data | capitalizeOrSpacing)
              : "---"
          }}
        </td>
        <td *ngIf="evaluation.matrixVersion === '4'" class="t-black">---</td>
        <td *ngIf="evaluation.matrixVersion !== '4'" class="t-black">---</td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias
            | filterBy : 'item' : 'billingFourYears' as criteria
        "
      >
        <td>Facturación</td>
        <td *ngIf="evaluation.matrixVersion === '4'">
          {{ criteria[0]?.others }}
        </td>
        <td *ngIf="evaluation.matrixVersion !== '4'">{{ fourYearsAgo }}</td>
        <td>
          {{
            criteria[0]?.data
              ? (criteria[0]?.data | capitalizeOrSpacing)
              : "---"
          }}
        </td>
        <td *ngIf="evaluation.matrixVersion === '4'" class="t-black">---</td>
        <td *ngIf="evaluation.matrixVersion !== '4'" class="t-black">---</td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias
            | filterBy : 'item' : 'billingThreeYears' as criteria
        "
      >
        <td>Facturación</td>
        <td *ngIf="evaluation.matrixVersion === '4'">
          {{ criteria[0]?.others }}
        </td>
        <td *ngIf="evaluation.matrixVersion !== '4'">{{ threeYearsAgo }}</td>
        <td>
          {{
            criteria[0]?.data
              ? (criteria[0]?.data | capitalizeOrSpacing)
              : "---"
          }}
        </td>
        <td *ngIf="evaluation.matrixVersion === '4'" class="t-black">---</td>
        <td *ngIf="evaluation.matrixVersion !== '4'" class="t-black">---</td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias
            | filterBy : 'item' : 'billingTwoYears' as criteria
        "
      >
        <td>Facturación</td>
        <td *ngIf="evaluation.matrixVersion === '4'">
          {{ criteria[0]?.others }}
        </td>
        <td *ngIf="evaluation.matrixVersion !== '4'">{{ twoYearsAgo }}</td>
        <td>
          {{
            criteria[0]?.data
              ? (criteria[0]?.data | capitalizeOrSpacing)
              : "---"
          }}
        </td>
        <td *ngIf="evaluation.matrixVersion === '4'" class="t-black">---</td>
        <td *ngIf="evaluation.matrixVersion !== '4'" class="t-black">---</td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias
            | filterBy : 'item' : 'billingOneYear' as criteria
        "
      >
        <td>Facturación</td>
        <td *ngIf="evaluation.matrixVersion === '4'">
          {{ criteria[0]?.others }}
        </td>
        <td *ngIf="evaluation.matrixVersion !== '4'">{{ oneYearsAgo }}</td>
        <td>
          {{
            criteria[0]?.data
              ? (criteria[0]?.data | capitalizeOrSpacing)
              : "---"
          }}
        </td>
        <td *ngIf="evaluation.matrixVersion === '4'" class="t-black">
          {{
            criteria[0]?.value
              ? (criteria[0]?.value | capitalizeOrSpacing) + "%"
              : "0%"
          }}
        </td>
        <td *ngIf="evaluation.matrixVersion !== '4'" class="t-black">
          {{
            criteria[0]?.value
              ? (criteria[0]?.value | capitalizeOrSpacing) + "%"
              : ""
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias | filterBy : 'item' : 'employeeCount' as criteria
        "
      >
        <td>Nro de trabajadores</td>
        <td></td>
        <td>
          {{
            criteria[0].data ? (criteria[0].data | capitalizeOrSpacing) : "---"
          }}
        </td>
        <td class="t-black">
          {{
            criteria[0].value
              ? (criteria[0].value | capitalizeOrSpacing) + "%"
              : "---"
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias
            | filterBy : 'item' : 'classification' as criteria
        "
      >
        <td>Clasificación</td>
        <td></td>
        <td>
          {{
            criteria[0].data ? (criteria[0].data | capitalizeOrSpacing) : "---"
          }}
        </td>
        <td class="t-black">
          {{
            criteria[0].value
              ? (criteria[0].value | capitalizeOrSpacing) + "%"
              : "0%"
          }}
        </td>
      </tr>
      <tr *ngIf="evaluation.criterias | filterBy : 'item' : 'ppp' as criteria">
        <td>PPP</td>
        <td></td>
        <td>
          {{
            criteria[0].data ? (criteria[0].data | capitalizeOrSpacing) : "---"
          }}
        </td>
        <td class="t-black">
          {{
            criteria[0].value
              ? (criteria[0].value | capitalizeOrSpacing) + "%"
              : "0%"
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias | filterBy : 'item' : 'directDebt' as criteria
        "
      >
        <td>Deuda directa</td>
        <td></td>
        <td>
          {{
            criteria[0].data ? (criteria[0].data | capitalizeOrSpacing) : "---"
          }}
        </td>
        <td class="t-black">
          {{
            criteria[0].value
              ? (criteria[0].value | capitalizeOrSpacing) + "%"
              : "0%"
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias | filterBy : 'item' : 'indirectDebt' as criteria
        "
      >
        <td>Deuda indirecta</td>
        <td></td>
        <td>
          {{
            criteria[0]?.data
              ? (criteria[0]?.data | capitalizeOrSpacing)
              : "---"
          }}
        </td>
        <td *ngIf="evaluation.matrixVersion === '1'" class="t-black"></td>
        <td *ngIf="evaluation.matrixVersion !== '1'" class="t-black">
          {{
            criteria[0]?.value
              ? (criteria[0]?.value | capitalizeOrSpacing) + "%"
              : "0%"
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias
            | filterBy : 'item' : 'mortgageGuarantees' as criteria
        "
      >
        <td>Garantías hipotecarias</td>
        <td></td>
        <td>
          {{
            criteria[0].data ? (criteria[0].data | capitalizeOrSpacing) : "---"
          }}
        </td>
        <td class="t-black">
          {{
            criteria[0].value
              ? (criteria[0].value | capitalizeOrSpacing) + "%"
              : "0%"
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          evaluation.criterias
            | filterBy : 'item' : 'unclearProtests' as criteria
        "
      >
        <td>Protestos sin aclarar</td>
        <td></td>
        <td>
          {{
            criteria[0].data ? (criteria[0].data | capitalizeOrSpacing) : "---"
          }}
        </td>
        <td class="t-black">
          {{
            criteria[0].value
              ? (criteria[0].value | capitalizeOrSpacing) + "%"
              : "0%"
          }}
        </td>
      </tr>
      <tr class="t-black">
        <td>Puntaje parcial</td>
        <td></td>
        <td></td>
        <td>{{ evaluation.totalValue ? evaluation.totalValue + "%" : "" }}</td>
      </tr>
    </ng-container>
  </table>
</div>

<div class="aditional-information">
  <h6>Información adicional</h6>
  <div class="code-table addtional">
    <table>
      <tr>
        <th>Año</th>
        <th>Importaciones</th>
        <th>Exportaciones</th>
      </tr>
      <ng-container *ngIf="evaluation?.additionalInfo; else empty">
        <tr *ngFor="let eval of evaluation.additionalInfo">
          <td>Total {{ eval.year }}</td>
          <td>
            <span>
              {{
                eval.importsValue ? (eval.importsValue | numberSpacing) : "0.00"
              }}
              ({{ eval.importsOpsValue ? eval.importsOpsValue : "0" }} ops)
            </span>
          </td>
          <td>
            <span>
              {{
                eval.exportsValue ? (eval.exportsValue | numberSpacing) : "0.00"
              }}
              ({{ eval.exportsOpsValue ? eval.exportsOpsValue : "0" }} ops)
            </span>
          </td>
        </tr>
        <tr class="t-black">
          <td>Promedio</td>
          <td>
            <span *ngIf="importAverage">
              {{ importAverage | numberSpacing }} ({{ importAverageOps }} ops)
            </span>
            <span *ngIf="!importAverage"> 0.00 (0 ops) </span>
          </td>
          <td>
            <span *ngIf="exportAverage">
              {{ exportAverage | numberSpacing }} ({{ exportAverageOps }} ops)
            </span>
            <span *ngIf="!exportAverage"> 0.00 (0 ops) </span>
          </td>
        </tr>
      </ng-container>

      <ng-template #empty> It's empty </ng-template>
    </table>
  </div>
</div>
<div class="action-buttons">
  <a class="button button-dark-gray" (click)="back()">Regresar</a>
</div>
