<mat-form-field
  *ngIf="!inputError"
  [appearance]="appearance"
  floatLabel="auto"
  [ngClass]="{'custom-mat-invalid-style': _errorStatus === true}"
>
  <mat-label>{{ label }}</mat-label>
  <input
    data-hj-allow
    [disabled]="(isControlInPendingStatus && _originalLabel === 'RUC') || isDisabled"
    matInput
    [type]="calculateType()"
    [placeholder]="placeholder? placeholder : _originalLabel"
    [(ngModel)]="value"
    [autocomplete]="autocomplete"
    [value]="value"
  />
  <button
    type="button"
    *ngIf="encrypted"
    mat-icon-button
    matSuffix
    (click)="toggleEncrypted()"
  >
    <mat-icon [ngStyle]="{'color':'#6C727C'}"
      >{{_encryptedVisibility ? 'visibility_off' : 'visibility'}}</mat-icon
    >
  </button>
  <mat-hint *ngIf="helperText"> {{ helperText }}</mat-hint>
  <img *ngIf="hasIcon" [className]="iconClass" matSuffix />
</mat-form-field>

<mat-form-field
  *ngIf="inputError"
  [appearance]="appearance"
  [ngClass]="{'custom-mat-invalid-style': _errorStatus === true}"
>
  <mat-label>{{ _originalLabel }}</mat-label>
  <input
    data-hj-allow
    [disabled]="(isControlInPendingStatus && _originalLabel === 'RUC') || isDisabled"
    matInput
    [type]="calculateType()"
    [placeholder]="placeholder? placeholder : _originalLabel"
    [(ngModel)]="value"
    [autocomplete]="autocomplete"
    [value]="value"
  />
  <mat-hint *ngIf="_errorStatus">
    <p [ngClass]="{'custom-mat-invalid-style': _errorStatus === true}">
      {{ getCleanErrorLabel() | capitalizeOnlyFirstLetter }}
    </p>
  </mat-hint>
  <img *ngIf="hasIcon" [className]="iconClass" matSuffix />
</mat-form-field>
