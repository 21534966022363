export enum Banks {
    "Banco de Comercio" = "Banco de Comercio",
    "Banco de Crédito del Perú" = "Banco de Crédito del Perú",
    "Banco Interamericano de Finanzas (BanBif)" = "Banco Interamericano de Finanzas (BanBif)",
    "Banco Pichincha" = "Banco Pichincha",
    "BBVA Continental" = "BBVA Continental",
    "Citibank Perú" = "Citibank Perú",
    "Interbank" = "Interbank",
    "MiBanco" = "MiBanco",
    "Scotiabank Perú" = "Scotiabank Perú",
    "Banco GNB Perú" = "Banco GNB Perú",
    "Banco Falabella" = "Banco Falabella",
    "Banco Ripley" = "Banco Ripley",
    "Banco Santander Perú" = "Banco Santander Perú",
    "Banco Azteca" = "Banco Azteca",
    "Banco Cencosud" = "Banco Cencosud",
    "ICBC PERU BANK" = "ICBC PERU BANK",
    "Banco de la Nación" = "Banco de la Nación",
    "Caja Arequipa" = "Caja Arequipa",
    "Caja Maynas"= "Caja Maynas",
    "Caja Sullana"= "Caja Sullana",
    "Financiera oh"= "Financiera oh",
    "Caja Trujillo"= "Caja Trujillo",
    "Caja Piura"= "Caja Piura",
}
