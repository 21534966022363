import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { Injectable } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { timer } from "rxjs";
import { UsersService } from "src/app/features/users/services/users.service";

@Injectable({ providedIn: "root" })
export class RUCValidlValidator {
  constructor(private usersService: UsersService) {}

  public isUserRucValid(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return new Promise((resolve, reject) => {
        return timer(500)
          .pipe(switchMap(() => this.usersService.validateRuc(control.value)))
          .subscribe(
            (res) => resolve(null),
            (err) => resolve({ isRucValid: true })
          );
      });
    };
  }
}
