import { OverlayService } from "src/app/shared/modules/overlay/services/overlay.service";
import { ComponentPortal } from "@angular/cdk/portal";
import { Clipboard } from "@angular/cdk/clipboard";
import { Overlay } from "@angular/cdk/overlay";
import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DebtorService } from "../../../services/debtor.service";
import { SaveResultComponent } from "src/app/shared/modules/overlay/ui/save-result/save-result.component";
import { AddNoteDialogComponent } from "../add-note-dialog/add-note-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { EvaluationsActions } from "src/app/features/risk-pending-evaluations/states/evaluations.action-types";
import { EvaluationSelectors } from "src/app/features/risk-pending-evaluations/states/evaluations.selector-types";
import { BehaviorSubject } from "rxjs";
import { ObservePayerComponent } from "../observe-payer/observe-payer.component";
import { AddContactsEmailsComponent } from "../add-contacts-emails/add-contacts-emails.component";
import { TelegramConfirmationComponent } from "../telegram-confirmation/telegram-confirmation.component";
import { AddtelegramComponent } from "../addtelegram/addtelegram.component";
import { AddContactDialogComponent } from "src/app/features/my-clients/UI/organisms/add-contact-dialog/add-contact-dialog.component";
import { AddContactConfirmationComponent } from "src/app/features/my-clients/UI/organisms/add-contact-confirmation/add-contact-confirmation.component";
import { InvoicesActions } from "src/app/features/invoices/state/invoices.action-types";
import { InvoiceSelectors } from "src/app/features/invoices/state/invoices.selector-types";
import { InvoiceService } from "src/app/features/invoices/services/invoice.service";
import { UsersSelectors } from "src/app/features/users/state/users.selector-types";
import { AppState } from "src/app/app.states";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { RejectContactDialogComponent } from "src/app/features/my-clients/UI/organisms/reject-contact-dialog/reject-contact-dialog.component";
import { SuplierService } from "src/app/features/my-supliers/services/supliers.service";

@Component({
  selector: "app-risk-debtor-detail",
  templateUrl: "./risk-debtor-detail.component.html",
  styleUrls: ["./risk-debtor-detail.component.scss"],
})
export class RiskDebtorDetailComponent implements OnInit, OnDestroy {
  debtor: any;
  isSectorEditionOn = false;
  isActivityEditionOn = false;
  debtorSector;
  debtorSectorDB;
  debtorActivity;
  debtorActivityDB;
  debtorRiskLevel;
  numberCopied = new BehaviorSubject<any>(false);
  evaluation;
  selected = 0;
  sectors = [
    "ABOGADOS",
    "ADMINISTRACION PUBLICA",
    "AGROINDUSTRIA Y GANADERIA",
    "ALQUILERES (MAQUINARIA)",
    "AUTOMOTRIZ",
    "BEBIDAS",
    "CAUCHO Y PLASTICO",
    "COMERCIALIZADORAS",
    "CONSTRUCTORAS",
    "CONSULTORIAS Y SERVICIOS",
    "CORREOS",
    "CURTIDURIA",
    "EDITORIALES",
    "EDUCACION",
    "ENERGIA Y AGUA",
    "ESPARCIMIENTO",
    "FORESTAL Y SILVICULTURA",
    "HIDROCARBUROS",
    "IMPRESIÓN",
    "INDUSTRIA ALIMENTARIA",
    "INFORMATICA",
    "INMOBILIARIAS",
    "INTERMEDIACION FINANCIERA",
    "INVESTIGACION Y DESARROLLO",
    "LABORATORIOS",
    "METALMECANICA",
    "MINERALES NO METALICOS",
    "MINERIA",
    "ORGANIZACIONES",
    "OTROS SERVICIOS",
    "OTROS SERVICIOS",
    "PAPEL",
    "PESCA",
    "QUIMICAS",
    "RECICLAJE",
    "SALUD",
    "TELECOMUNICACIONES",
    "TEXTILES",
    "TRANSPORTE",
    "TURISMO",
    "VETERINARIAS",
  ];
  riskLevels = [
    {
      name: "Riesgo alto",
      value: "high",
      color: "red",
    },
    {
      name: "Riesgo moderado",
      value: "medium",
      color: "yellow",
    },
    {
      name: "Riesgo bajo",
      value: "low",
      color: "green",
    },
    {
      name: "Judicial",
      value: "judicial",
      color: "blue",
    },
    {
      name: "Ninguno",
      value: "none",
      color: "gray",
    },
  ];
  seletedDebor;
  showPostponeIcon = false;
  subscription;
  isLoading;
  selectedDebtor;
  dataTelegram;
  openedModal = false;
  companyDetailDB = false;
  newEvaluationLoading = false;
  telegramModalData: any = null;
  contacts;
  openModalContact = false;
  contactInformation;
  isValidating = false;
  loggedInUserRole;
  collectionEmailReady;
  addContactMode;

  constructor(
    public _invoiceService: InvoiceService,
    private clipboard: Clipboard,
    private location: Location,
    private debtorService: DebtorService,
    private route: ActivatedRoute,
    private overlay: Overlay,
    public dialog: MatDialog,
    private overlayService: OverlayService,
    private router: Router,
    private store: Store<AppState>,
    private supplierService: SuplierService
  ) {
    this.showPostponeIcon =
      this.router.getCurrentNavigation().extras?.state?.showPostpone;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getRoleUser();
      if (params?.debtorId) {
        this.seletedDebor = params.debtorId;
        this.fetchDebtor(params.debtorId);
      }
    });

    this.subscription = this.overlayService
      .getCloseEmitter()
      .subscribe((obs: any) => {
        //open modal evaluation after click cancel on confirmation screen
        if (this.openedModal) {
          this.editTelegram(this.debtor);
        } else if (this.openModalContact) {
          if (this.addContactMode === "edit") {
            this.editValidContacts(this.contactInformation);
          }
          if (this.addContactMode === "add") {
            this.addContact(this.contactInformation);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getRoleUser() {
    this.store
      .select(UsersSelectors.profileProperty("role"))
      .pipe(take(1))
      .subscribe((role) => (this.loggedInUserRole = role));
  }

  fetchDebtor(id) {
    this.debtorService.getById(id).subscribe((res) => {
      let accountManagerCounter = 0;
      res.accountManagers = res?.accountManagers?.filter((ac) => {
        if (ac?.role != "risk analyst" || accountManagerCounter == 0) {
          if (ac?.role == "risk analyst") {
            accountManagerCounter++;
          }
          return ac;
        }
      });

      this.selectedDebtor = id;
      this.evaluation = res.evaluation;

      //console.log(res);

      this.debtor = res;
      this.debtorSectorDB = res.sector;
      this.debtorSector = res.sector;
      this.debtorActivity = res.activity;
      this.debtorActivityDB = res.activity;
      this.debtorRiskLevel = res.riskLevel;
      this.openedModal = false;
      this.telegramModalData = null;

      this.debtorService
        .getContacts(this.debtor.companyRuc, "")
        .subscribe((res) => {
          this.contacts = res;
          this.checkValidContactsCollection();
        });
    });
  }

  checkValidContactsCollection() {
    if (this.contacts?.debtorContacts.collectionEmailReady) {
      this.collectionEmailReady =
        this.contacts?.debtorContacts.collectionEmailReady;
    } else {
      this.collectionEmailReady = false;
    }
  }

  // getById(){
  //   this.debtorService.getById(this.selectedDebtor).subscribe(res => {

  //   })
  // }

  reFetchDebtor() {
    this.fetchDebtor(this.seletedDebor);
  }
  copyToClipboard(data) {
    this.clipboard.copy(data);
    this.numberCopied.next(true);
    setTimeout(() => {
      this.numberCopied.next(false);
    }, 1000);
  }

  goBack() {
    if (this.location.back() === undefined) {
      this.router.navigateByUrl("/operations");
    } else {
      this.location.back();
    }
  }

  mapStatus(val) {
    switch (val) {
      case "approved":
        return { label: "Aprobado", color: "green" };
      case "rejected":
        return { label: "Denegado", color: "red" };
      case "pending":
        return { label: "Pendiente", color: "yellow" };
      case "pending":
        return { label: "Pendiente", color: "yellow" };
      case "observation":
        return { label: "En observación", color: "dark-gray" };
      default:
        return { label: "Sin evaluación", color: "dark-gray" };
    }
  }

  mapCreditLine(val) {
    switch (val) {
      case "line":
        return { label: "Línea", icon: "L" };
      case "punctual":
        return { label: "Puntual", icon: "P" };
      default:
        return { label: "-", icon: "-" };
    }
  }

  switchBoolean(val) {
    this[val] = !this[val];
    this.debtorSector = this.debtorSectorDB;
    this.debtorActivity = this.debtorActivityDB;
  }

  openSaveResult() {
    const portalComponent = new ComponentPortal(SaveResultComponent);

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: "dark-backdrop",
      panelClass: ["full-width", "reset-display"],
    });
    const componentRef = overlayRef.attach(portalComponent);
    componentRef.instance.success = { savingSucceeded: true };
    componentRef.instance.resultConfig = {
      onSuccess: {
        title: "Cambio realizado",
        showIcon: true,
        iconName: "big-check-mark-icon",
      },
    };

    componentRef.instance.onDone.subscribe(() => {
      componentRef.destroy();
      overlayRef.dispose();
    });
  }

  openDialogHelper(dialogComp, data?) {
    const dialogRef = this.dialog.open(dialogComp, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      position: {
        left: "0",
      },
      data: data,
      autoFocus: false,
    });

    return dialogRef;
  }

  connectDialogListeners(dialogRef, callback) {
    dialogRef.afterClosed().subscribe(callback);
  }

  posponeEvaluation() {
    let id = this.evaluation._id;
    this.overlayService.startConfirmation(
      {
        status: "pospone",
        _id: id,
      },
      null,
      "question",
      "edit",
      null,
      EvaluationsActions.Saving,
      `risk-pending-evaluations`,
      EvaluationSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: "La evaluación se ha postergado",
        },
      }
    );
  }

  getClassRed(entity) {
    if (
      Number(entity?.usedCreditLine || 0) >
      Number(entity?.evaluation?.creditLine || 0)
    ) {
      return "eval-red";
    }
  }

  editNote(debtor: any) {
    let dialogRef = this.dialog.open(AddNoteDialogComponent, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      position: {
        left: "0",
      },
      data: { note: debtor?.riskNote },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.overlayService.startConfirmation(
          {
            riskNote: result.note,
            _id: debtor._id,
          },
          null,
          "resultOnly",
          "edit",
          null,
          EvaluationsActions.SavingNote,
          null,
          EvaluationSelectors.savingSuccessStateById,
          {
            onSuccess: {
              showCheck: true,
              title: "Nota actualizada",
            },
          },
          this.fetchDebtor.bind(this, this.seletedDebor)
        );
      }
    });
  }

  observePayer(debtor: any) {
    if (debtor.isObserve !== undefined && debtor.isObserve) {
      this.overlayService.startConfirmation(
        {
          _id: debtor._id,
          isObserve: false,
        },
        null,
        "question",
        "edit",
        null,
        EvaluationsActions.SavingNote,
        null,
        EvaluationSelectors.savingSuccessStateById,
        {
          onConfirm: {
            title: "¿Estás seguro?",
          },
          onSuccess: {
            showCheck: true,
            title: "Se ha eliminado la observación",
          },
        },
        this.fetchDebtor.bind(this, this.seletedDebor)
      );
    } else {
      let dialogRef = this.dialog.open(ObservePayerComponent);
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.overlayService.startConfirmation(
            {
              riskComment: result.riskComment,
              _id: debtor._id,
              isObserve: true,
            },
            null,
            "resultOnly",
            "edit",
            null,
            EvaluationsActions.SavingNote,
            null,
            EvaluationSelectors.savingSuccessStateById,
            {
              onSuccess: {
                showCheck: true,
                title: "Pagador en observación",
              },
            },
            this.fetchDebtor.bind(this, this.seletedDebor)
          );
        }
      });
    }
  }

  deleteNote(debtor: any) {
    this.overlayService.startConfirmation(
      {
        debtor: debtor._id,
        _id: debtor._id,
      },
      null,
      "question",
      "delete",
      null,
      EvaluationsActions.SavingNote,
      null,
      EvaluationSelectors.savingSuccessStateById,
      {
        onSuccess: {
          showCheck: true,
          title: "Nota eliminada",
        },
      },
      this.fetchDebtor.bind(this, this.seletedDebor)
    );
  }

  editContacts($event) {
    let debtor = this.debtor;

    const dialogRef = this.dialog.open(AddContactsEmailsComponent, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      position: {
        left: "0",
      },
      data: { debtor },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fetchDebtor(this.seletedDebor);
      }
    });
  }

  editTelegram(debtor: any) {
    this.openedModal = true;

    let dialogRef = this.dialog.open(AddtelegramComponent, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      position: {
        left: "0",
      },
      data: { debtor: this.telegramModalData || debtor },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event !== "Cancel") {
          this.telegramModalData = {
            _id: debtor._id,
            companyDetail: result.companyDetail,
            detailUpdatedAt: new Date(),
          };

          this.overlayService.startConfirmation(
            {
              _id: debtor._id,
              companyDetail: result.companyDetail,
            },
            {
              component: TelegramConfirmationComponent,
              entity: {
                companyDetail: result.companyDetail,
                detailUpdatedAt: new Date(),
              },
            },
            "details",
            "edit",
            null,
            EvaluationsActions.SavingNote,
            null,
            EvaluationSelectors.savingSuccessStateById,
            {
              onSuccess: {
                showCheck: true,
                title: "Información actualizada",
              },
            },
            this.fetchDebtor.bind(this, this.seletedDebor)
          );
        } else {
          this.telegramModalData = null;
        }
      }
    });
  }

  saveSector(data: any) {
    if (data.debtor && data.sector) {
      this.debtorService
        .updateOneField(this.debtor._id, { sector: data.sector })
        .subscribe((res) => {
          this.openSaveResult();
          this.fetchDebtor(this.seletedDebor);
        });
    }
  }

  saveActivity(data: any) {
    if (data.debtor && data.activity) {
      this.debtorService
        .updateOneField(this.debtor._id, { activity: data.activity })
        .subscribe((res) => {
          this.openSaveResult();
          this.fetchDebtor(this.seletedDebor);
        });
    }
  }

  saveRiskLevel(data: any) {
    if (data.debtor && data.riskLevel) {
      this.debtorService
        .updateOneField(this.debtor._id, { riskLevel: data.riskLevel })
        .subscribe((res) => {
          this.openSaveResult();
          this.fetchDebtor(this.seletedDebor);
        });
    }
  }

  postponeEvaluation(data: any) {
    if (data?.evaluation?._id) {
      this.overlayService.startConfirmation(
        {
          status: data?.evaluation?.status,
          _id: data?.evaluation?._id,
        },
        null,
        "question",
        "edit",
        null,
        EvaluationsActions.Saving,
        null,
        EvaluationSelectors.savingSuccessStateById,
        {
          onSuccess: {
            showCheck: true,
            title: "La evaluación se ha postergado",
          },
        },
        this.fetchDebtor.bind(this, this.seletedDebor)
      );
    }
  }

  newEvaluation(debtorData: any) {
    let debtor = {
      debtor: debtorData._id,
    };
    this.newEvaluationLoading = true;
    this.debtorService.createEvaluation(debtor).subscribe((data) => {
      if (data) {
        this.newEvaluationLoading = false;
        setTimeout(() => {
          this.fetchDebtor(this.seletedDebor);
        }, 700);
      }
    });
  }

  evaluationDetail(evaluation: any) {
    this.router.navigateByUrl(`/risk-pending-evaluations/${evaluation._id}`);
  }

  addContact(data?) {
    this.addContactMode = "add";
    let dialogRef = this.openDialogHelper(AddContactDialogComponent, {
      ruc: this.debtor.companyRuc,
      debtor: this.debtor._id,
      showContactTypes: this.loggedInUserRole == "risk analyst" ? true : false,
      data,
    });

    this.connectDialogListeners(dialogRef, (result) => {
      this.openModalContact = true;

      if (result) {
        this.contactInformation = { ...result };

        result.debtor = this.debtor._id;

        this.overlayService.startConfirmation(
          { ...result, _id: this.debtor._id },
          {
            component: AddContactConfirmationComponent,
            entity: { ...result },
          },
          "details",
          "create",
          null,
          InvoicesActions.SaveContact,
          `risk-debtors/${this.debtor._id}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onConfirm: {
              title: "Confirmación datos del contacto",
            },
            onSuccess: {
              showCheck: true,
              title: "Se agregó el contacto correctamente",
            },
          },
          this.getContacts.bind(this)
        );
      } else {
        this.openModalContact = false;
      }
    });
  }

  getContacts() {
    this.openModalContact = false;
    this.contactInformation = "";
    setTimeout(() => {
      this.debtorService
        .getContacts(this.debtor.companyRuc, "")
        .subscribe((res) => {
          this.contacts = res;
          this.checkValidContactsCollection();
        });
    }, 100);
  }

  editValidContacts($event) {
    console.log($event);
    this.addContactMode = "edit";
    let contactInfo;
    let data = {
      mode: "edit",
    };
    contactInfo = this.contacts?.debtorContacts.validatedContacts.find(
      (con) => con.email == $event.email
    );

    let contact = this.contactInformation
      ? this.contactInformation
      : contactInfo;
    data = { ...data, ...contact };

    let dialogRef = this.openDialogHelper(AddContactDialogComponent, {
      ruc: this.debtor.companyRuc,
      debtor: this.debtor._id,
      data,
      showContactTypes: true,
    });

    this.connectDialogListeners(dialogRef, (result) => {
      this.openModalContact = true;
      if (result) {
        this.contactInformation = { ...result };

        this.overlayService.startConfirmation(
          { ...result, debtor: this.debtor._id },
          {
            component: AddContactConfirmationComponent,
            entity: { ...result },
          },
          "details",
          "edit",
          null,
          InvoicesActions.SaveContact,
          `risk-debtors/${this.debtor._id}`,
          InvoiceSelectors.savingSuccessStateById,
          {
            onConfirm: {
              title: "Confirmación datos del contacto",
            },
            onSuccess: {
              showCheck: true,
              title: "Se editó el contacto correctamente",
            },
          },
          this.getContacts.bind(this)
        );
      } else {
        this.openModalContact = false;
      }
    });
  }
  validate($event) {
    // if (this.isValidating) return;
    // this.isValidating = true;
    let data = {
      mode: "validate",
    };
    let contact;
    if ($event.isValid) {
      contact = this.contacts?.debtorContacts.unValidatedContacts.find(
        (con) => con.email == $event.email
      );
      data = { ...data, ...contact };
      let dialogRef = this.openDialogHelper(AddContactDialogComponent, {
        ruc: this.debtor.companyRuc,
        debtor: this.debtor._id,
        data,
        showContactTypes:
          this.loggedInUserRole == "risk analyst" ? true : false,
      });

      this.connectDialogListeners(dialogRef, (result) => {
        if (result) {
          this.contactInformation = { ...result };
          result._id = contact._id;
          result.user = contact.user;
          result.debtor = this.debtor._id;
          result.companyRuc = this.debtor.companyRuc;

          this.overlayService.startConfirmation(
            { ...result, debtor: this.debtor._id },
            {
              component: AddContactConfirmationComponent,
              entity: { ...result },
            },
            "details",
            "edit",
            null,
            InvoicesActions.ValidContact,
            `risk-debtors/${this.debtor._id}`,
            InvoiceSelectors.savingSuccessStateById,
            {
              onConfirm: {
                title: "Confirmación datos del contacto",
              },
              onSuccess: {
                showCheck: true,
                title: "Se validó el contacto correctamente",
              },
            },
            this.getContacts.bind(this)
          );

          // this._invoiceService
          //   .validateContact(result, this.debtor.companyRuc)
          //   .subscribe((res: any) => {
          //     this.contacts.debtorContacts = res.debtorContacts;
          //     this.isValidating = false;
          //     this.contactInformation = ""
          //   });
        }
      });
    } else {
      // contact = this.contacts?.debtorContacts.validatedContacts.find(
      //   (con) => con.email == $event.email
      // );
      // this._invoiceService
      //   .unvalidateContact(contact, this.debtor._id, this.debtor.companyRuc)
      //   .subscribe((res: any) => {
      //     this.contacts.debtorContacts = res.debtorContacts;
      //     this.isValidating = false;

      //   });

      this.isValidating = false;
      contact = this.contacts?.debtorContacts.validatedContacts.find(
        (con) => con.email == $event.email
      );

      const callback = () => {
        this.getContacts();
      };
      data = { ...data, ...contact };
      let dialogRef = this.openDialogHelper(RejectContactDialogComponent, {
        ruc: this.debtor.companyRuc,
        debtor: this.debtor._id,
        data,
        showContactTypes:
          this.loggedInUserRole == "risk analyst" ? true : false,
      });

      this.connectDialogListeners(dialogRef, (result) => {
        if (result) {
          console.log(result);
          this.overlayService.startConfirmation(
            {
              ...contact,
              _id: this.debtor._id,
              companyRuc: this.debtor.companyRuc,
              rejectionReason: result,
            },
            {
              component: AddContactConfirmationComponent,
              entity: { ...contact },
            },
            "details",
            "delete",
            null,
            InvoicesActions.SaveContact,
            `risk-debtors/${this.debtor._id}`,
            InvoiceSelectors.savingSuccessStateById,
            {
              onConfirm: {
                title: "Quitar de contactos validados",
              },
              onSuccess: {
                showCheck: true,
                title: "Se invalidó el contacto",
              },
            },
            callback.bind(this)
          );
        }
      });

      // this.overlayService.startConfirmation(
      //   {
      //     ...contact,
      //     _id: this.debtor._id,
      //     companyRuc: this.debtor.companyRuc,
      //   },
      //   {
      //     component: AddContactConfirmationComponent,
      //     entity: { ...contact },
      //   },
      //   'details',
      //   'delete',
      //   null,
      //   InvoicesActions.SaveContact,
      //   `risk-debtors/${this.debtor._id}`,
      //   InvoiceSelectors.savingSuccessStateById,
      //   {
      //     onConfirm: {
      //       title: 'Quitar de contactos validados',
      //     },
      //     onSuccess: {
      //       showCheck: true,
      //       title: 'Se invalidó el contacto',
      //     },
      //   },
      //   callback.bind(this)
      // );
    }
  }

  async readyContact($event) {
    this.supplierService
      .updateReadyContacts(this.debtor.companyRuc, $event)
      .subscribe((result) => {
        this.debtor.contactsReady = result.contactsReady;
      });
  }
}
