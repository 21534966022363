import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment';
import { RequiredValidator } from 'src/app/shared/validators/required.validator';
import { InvoiceService } from '../../../services/invoice.service';

@Component({
  selector: 'app-invoice-for-sale-dialog',
  templateUrl: './invoice-for-sale-dialog.component.html',
  styleUrls: ['./invoice-for-sale-dialog.component.scss'],
})
export class InvoiceForSaleDialogComponent implements OnInit {
  form: FormGroup;
  timeFrames;
  direction = 'column';
  _selectedValue;
  duration;
  defaultOption;
  invoices;
  checkedAll = false;
  invoicesSelected;
  telegramError;
  evaluationFinishedAt = new Date();

  constructor(
    public dialogRef: MatDialogRef<InvoiceForSaleDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _service: InvoiceService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    const invoiceId = this._router.url.split('/')[2] || this.data._id;
    this.getInvoices(invoiceId);
    moment.locale('es');
    this.form = this.formBuilder.group({
      onSaleTime: ['', [RequiredValidator]],
    });
    this.getArrayOfDate();
    if (this.data?.onSaleTime) {
      this.form.controls['onSaleTime'].setValue(this.defaultOption);
    }

    this.telegramError = this.validateTelegram(this.data);
  }

  getInvoices(entityId) {
    this._service
      .getAllInvoicesSameDebtorAndStatus(entityId)
      .subscribe((data) => {
        this.invoices = data;
      });
  }

  getCodes(invoice) {
    return invoice.physicalInvoices.map((i) => i.code).join('\n');
  }

  setSelection(invoice) {
    this.checkedAll = false;
    let invoicesObjs = this.invoices,
      objIndex;
    objIndex = invoicesObjs.findIndex((ob) => ob._id == invoice._id);
    invoicesObjs[objIndex].selected = !invoicesObjs[objIndex].selected;
    this.invoices = invoicesObjs;
    this.checkIfAllselected();
    this.invoiceSelected();
  }

  selectAll() {
    this.checkedAll = !this.checkedAll;
    this.invoices.map((invoice) => (invoice.selected = this.checkedAll));
    this.invoiceSelected();
  }

  someComplete(): boolean {
    if (this.invoices == null) {
      return false;
    }
    return (
      this.invoices.filter((inv) => inv.selected).length > 0 && !this.checkedAll
    );
  }

  checkIfAllselected() {
    if (
      this.invoices.filter((inv) => inv.selected).length ===
      this.invoices?.length
    ) {
      this.checkedAll = true;
    }
  }
  invoiceSelected() {
    this.invoicesSelected = this.invoices.filter((invoice) => invoice.selected);
  }

  getArrayOfDate() {
    let arrayDates = [];
    let now = new Date();
    let x = {
      hours: 12,
      minutes: 30,
      seconds: 0,
    };
    let y = {
      hours: 17,
      minutes: 30,
      seconds: 0,
    };

    if (this.check_time_weekday(x) && this.check_time_weekday(y)) {
      let date1 = this.setTimeFrame(now, x);
      let obj = this.buildObject(date1);
      arrayDates.push(obj);

      let date2 = this.setTimeFrame(now, y);
      let obj2 = this.buildObject(date2);
      arrayDates.push(obj2);

      // let date3 = this.get_next_weekday(now)
      // date3 = this.setTimeFrame(date3, x)
      // let obj3 = this.buildObject(date3)
      // arrayDates.push(obj3);

      // let now2 = new Date();
      // let date4 = this.get_next_weekday(now2)
      // date4 = this.setTimeFrame(date4, y)
      // let obj4 = this.buildObject(date4)
      // arrayDates.push(obj4);
    }

    if (!this.check_time_weekday(x) && !this.check_time_weekday(y)) {
      let date = this.get_next_weekday(now);
      date = this.setTimeFrame(date, x);
      let obj = this.buildObject(date);
      arrayDates.push(obj);

      let now2 = new Date();
      now2.setHours(0, 0, 0, 0);
      let date2 = this.get_next_weekday(now2);
      date2 = this.setTimeFrame(date2, y);
      let obj2 = this.buildObject(date2);
      arrayDates.push(obj2);

      // let date3 = this.get_next_weekday(date)
      // let obj3 =  this.buildObject(date3)
      // arrayDates.push(obj3);

      // let date4 = this.get_next_weekday(date2)
      // let obj4 =  this.buildObject(date4)
      // arrayDates.push(obj4);
    }

    if (!this.check_time_weekday(x) && this.check_time_weekday(y)) {
      let date = this.setTimeFrame(now, y);
      let obj = this.buildObject(date);
      arrayDates.push(obj);

      let date2 = this.get_next_weekday(date);
      date2 = this.setTimeFrame(date2, x);
      let obj2 = this.buildObject(date2);
      arrayDates.push(obj2);

      // let date3 = this.get_next_weekday(date)
      // date3 = this.setTimeFrame(date2, y);
      // let obj3 = this.buildObject(date3)
      // arrayDates.push(obj3);

      // let date4 = this.get_next_weekday(date2)
      // date4 = this.setTimeFrame(date4, x)
      // let obj4 = this.buildObject(date4)
      // arrayDates.push(obj4);
    }
    this.timeFrames = arrayDates.slice(0, 2); //remove last two dates
    this.setDefaultValue();
  }

  setDefaultValue() {
    let date = new Date(this.data?.onSaleTime);
    if (this.data?.onSaleTime) {
      this.defaultOption = moment(date).format();
      this.changeSelection(this.defaultOption);
    }
  }

  onSubmit() {
    if (!this.telegramError) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();

      if (this.form.valid) {
        let date = new Date(this.form.value.onSaleTime);
        let slot = date.getHours() === 12 ? 1 : 2;
        let obj = {
          onSaleDate: moment(this.form.value.onSaleTime).format('YYYY-MM-DD'),
          onSaleTime: this.form.value.onSaleTime,
          onSaleSlot: slot.toString(),
          selectedInvoices: this.invoicesSelected,
        };
        this.dialogRef.close(obj);
      }
    }
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  get_next_weekday(date, addedValue = 1) {
    let tomorrow = new Date(date.setDate(date.getDate() + addedValue));
    return tomorrow.getDay() % 6 ? tomorrow : this.get_next_weekday(tomorrow);
  }

  check_time_weekday(x) {
    let date = new Date();
    date.setHours(x.hours);
    date.setMinutes(x.minutes);
    date.setSeconds(x.seconds);
    let dtNow = new Date();
    if (date.valueOf() - dtNow.valueOf() > 0) {
      return true;
    } else {
      return false;
    }
  }

  setTimeFrame(date, timeFrame) {
    date.setHours(timeFrame.hours);
    date.setMinutes(timeFrame.minutes);
    date.setSeconds(timeFrame.seconds);

    return date;
  }

  buildObject(date) {
    let obj = {
      text: moment(date).format('dddd DD [de] MMMM, HH:mm'),
      value: moment(date).format(),
    };
    return obj;
  }
  propagateChange = (_: any) => {};

  changeSelection(value) {
    let today = new Date();
    let schedule = new Date(value);

    let diff = schedule.getTime() - today.getTime();

    let msec = diff;
    let hh = `0${Math.floor(msec / 1000 / 60 / 60)}`;
    msec -= parseInt(hh) * 1000 * 60 * 60;

    let mm = `0${Math.floor(msec / 1000 / 60)}`;
    msec -= parseInt(mm) * 1000 * 60;

    let ss = `0${Math.floor(msec / 1000)}`;
    msec -= parseInt(ss) * 1000;

    this.duration = hh.slice(-2) + ':' + mm.slice(-2) + ':' + ss.slice(-2);
  }

  validateTelegram(invoice: any) {
    let error = null;

    let errors = [];
    if (!invoice?.evaluation?.debtor?.activity) {
      errors.push('actividad');
    }

    if (!invoice?.evaluation?.debtor?.sector) {
      errors.push('sector');
    }

    if (
      invoice?.evaluation?.numWorkers == '' ||
      invoice?.evaluation?.numWorkers == undefined ||
      invoice?.evaluation?.numWorkers == null
    ) {
      errors.push('número de trabajadores');
    }

    if (errors.length) {
      return `El Telegram no contiene ${errors.join(
        ', '
      )}. Comunícate con Riesgos para actualizarlo.`;
    }

    this.evaluationFinishedAt = new Date(
      invoice?.evaluation?.evaluationFinishedAt
    );

    // if (moment().diff(detailUpdatedAt, 'days') > 60) {
    //   error =
    //     'La fecha de actualización de Telegram debe ser menor a 60 días.';
    // } else if (
    //   evaluationFinishedAt
    //     .startOf('day')
    //     .isAfter(detailUpdatedAt.startOf('day'), 'date')
    // ) {
    //   error =
    //     'La fecha de actualización de Telegram debe ser la misma o después que la fecha de dictamen de la evaluación vigente.';
    // }

    return error;
  }
}
