<lib-tab-payer-detail-view [rol]="loggedInUserRole" [selected]="selected">
  <div class="risk-detail">
    <lib-risk-detail
      [role]="loggedInUserRole"
      [debtor]="debtor"
      [newEvaluationLoading]="newEvaluationLoading"
      (editNote)="editNote($event)"
      (observePayer)="observePayer($event)"
      (deleteNote)="deleteNote($event)"
      (editTelegram)="editTelegram($event)"
      (saveSector)="saveSector($event)"
      (saveActivity)="saveActivity($event)"
      (saveRiskLevel)="saveRiskLevel($event)"
      (evaluationDetail)="evaluationDetail($event)"
      (newEvaluation)="newEvaluation($event)"
      (postponeEvaluation)="postponeEvaluation($event)"
      [showPostpone]="showPostponeIcon"
    >
    </lib-risk-detail>
  </div>
  <div class="contacts-detail">
    <div class="contact-group">
      <lib-contact-group
        (addContacts)="addContact($event)"
        (editContactEvt)="editValidContacts($event)"
        [companyHeader]="'Empresa pagadora'"
        [companyName]="debtor?.companyName"
        [companyRuc]="debtor?.companyRuc"
        [contacts]="contacts?.debtorContacts"
        [viewerRole]="loggedInUserRole"
        [validEmailTypes]="!collectionEmailReady"
        (validateContactEv)="validate($event)"
        (readyContactEvt)="readyContact($event)"
        [contactsReady]="debtor?.contactsReady"
      >
      </lib-contact-group>
    </div>
  </div>
</lib-tab-payer-detail-view>

<br /><br />
<a class="button button-dark-gray" (click)="goBack()">Regresar</a>
