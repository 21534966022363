<div class="container">
    <div class="payer-description">
        <div class="disclaimer-container">
            <!-- <app-invest-email-verified-disclaimer *ngIf="userStatus === 'email verified'" [alert]='disclaimerAlert'
                [status]="userStatus" [visible]="isDisclaimerVisible">
            </app-invest-email-verified-disclaimer> -->

            <app-invest-schedule-alert
                *ngIf="(userStatus !== 'email verified') && (payload.hasOwnProperty('onSale') && payload?.onSale === false)"
                [alert]='disclaimerAlert' [payload]="payload">
            </app-invest-schedule-alert>
        </div>



        <div *ngIf="false" class="payer-and-currency row">
            <div class="payer-container">
                <app-payer-description [title]="'Pagador'" [payer]="payload.client" [currency]="payload.type"
                    [description]="payload.description"></app-payer-description>
            </div>
        </div>
        <div class="intercom-container-invest-in-invoice">
            <div class="bank-and-currency companyName">
                <div class="bank-container">
                    <p class="tiny tiny-title">Empresa pagadora</p>
                    <p class="bold">{{payload.debtorCompanyName || payload.debtor.companyName | uppercase}}
                    </p>
                </div>
                <div class="bank-container row-button desktop">
                    <lib-button-fab (onClickHistoricalButton)="openHistoric($event)" label="Mi historial">
                    </lib-button-fab>
                </div>
                <!-- <div class="bank-container right">
                    <app-realtime-connection-status></app-realtime-connection-status>
                </div> -->
            </div>
            <div class="bank-container row-button mobile">
                <lib-button-fab [onlyIcon]="true" (onClickHistoricalButton)="openHistoric($event)" label="Mi historial">
                </lib-button-fab>
            </div>

            <div class="risk-row" *ngIf="payload?.evaluation">
                <div>
                    <div class="icon-row clickable mw-100" (click)="ratingDisclaimerOpen=!ratingDisclaimerOpen">
                        <lib-rating [rating]="payload?.evaluation?.rating"></lib-rating>
                        <div class="icon-row-inner rating-question">
                            <span>{{ratingDisclaimerOpen ? 'Ocultar' : '¿Qué es el Rating?'}}</span>
                            <img [className]="ratingDisclaimerOpen ? 'expand-less' : 'expand-more'">
                        </div>
                    </div>

                    <div class="rating-disclaimer-transition"
                        [ngClass]="(ratingDisclaimerOpen === true) ? 'rating-disclaimer-appear' : ''">
                        <div class="rating-disclaimer">
                            <p class="p-description">Esta clasificación de riesgo es solo una herramienta orientativa
                                calculada según la
                                Metodología de
                                Finsmart. No es una asesoría, respaldo ni recomendación de inversión.</p>
                            <div class="icon-row link">
                                <div>
                                    <a class="hyperlink" target="_blank" #more
                                        href="https://storage.googleapis.com/finsmart-websitefiles/legal/clasificacion-empresas.pdf">Quiero
                                        saber
                                        más</a>
                                </div>
                                <img class="svg-blue" (click)="more.click()"
                                    src="../../../../../../assets/icons/arrow_forward-24px.svg">
                            </div>
                        </div>
                    </div>

                </div>
                <div class="icon-row icon-gap">
                    <div class="icon-box">
                        <img src="../../../../../../assets/icons/business.svg">
                    </div>
                    <div class="icon-text">
                        <p class="p-title">Sector</p>
                        <p class="p-value">{{payload?.evaluation?.debtor?.sector | uppercase}}</p>
                    </div>
                </div>
                <div class="icon-row icon-gap activity">
                    <div class="icon-box">
                        <img src="../../../../../../assets/icons/store.svg">
                    </div>
                    <div class="icon-text">
                        <p class="p-title">Actividad</p>
                        <p class="p-value">{{payload?.evaluation?.debtor?.activity | uppercase }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!--
        <div>
            <app-invoice-info-investment [payload]="payload"></app-invoice-info-investment>
        </div> -->
        <!-- <div class="mt-10">
            <app-operation-invoice [isConfirming]="payload.isConfirming"></app-operation-invoice>
        </div> -->

        <div>

            <div class="investment">
                <div class="invest-form-container">
                    <h6
                        *ngIf="(userStatus !== 'email verified') && (!payload.hasOwnProperty('onSale') || payload?.onSale === true)">
                        Quiero invertir</h6>
                    <h6
                        *ngIf="(userStatus !== 'email verified') && (payload.hasOwnProperty('onSale') && payload?.onSale === false)">
                        Simula tu inversión</h6>
                    <p class="balance-text">Tu saldo disponible es: <span class="bank-account-number">{{payload.currency
                            | uppercase}} {{ availableBalance | numberSpacing }}</span></p>
                    <form [formGroup]="form" novalidate>
                        <div class="row">
                            <div class="intercom-input-invest">
                                <app-input inputError="true" formControlName="amount" (focusout)="sendEvent('amount')"
                                    autocomplete="off" type="number" label="Monto de inversión">
                                </app-input>
                                <div [ngClass]="{'schedule-invoice' : (payload.hasOwnProperty('onSale') && payload?.onSale === false)}"
                                    class="new-button icon-row-inner clickable" (click)="submit($event)">
                                    <h1 class="new-button-text">Invertir</h1>
                                    <lib-icon [color]="'white'" icon="arrow_forward"></lib-icon>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- <div class="form balance">
                    <p class="tiny tiny-title">(cuentas con {{payload.currency | uppercase}}
                        {{ availableBalance | numberSpacing }}
                        disponibles)</p>
                </div> -->
            </div>


            <div class="row mt-5">
                <div class="amount height-fix">
                    <div *ngIf="payload?.onSale == true && userRole != 'admin'">
                        <p class="tiny tiny-title mw-110">Monto disponible de la factura</p>
                        <div>
                            <app-amount [color]="'text-gray'" [amount]="InvoiceAvailableBalance"></app-amount>
                        </div>
                    </div>
                </div>
                <div class="roi">
                    <p class="tiny tiny-title">Retorno bruto esperado</p>
                    <!-- <p class="tiny tiny-title">Retorno esperado</p> -->
                    <div class="roi-item">
                        <span class="currency-time whole">+ {{payload.currency | uppercase}} </span>
                        <app-roi [amount]="calculatedROI"></app-roi>
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="time-limit">
                    <app-solid-label width="70" background="dark-gray" [content]="payload.minimumDuration + ' días'">
                    </app-solid-label>
                </span>
            </div>
            <!--div class="button-container">
                <fs-ui-button action="submit" (click)="submit($event)" [label]="'Invertir'"></fs-ui-button>
            </div-->
        </div>
    </div>

    <h6 class="aditional-info">Información importante</h6>

    <lib-tab-invest-in-invoice [riskInfo]="payload?.evaluation" (selectedTab)="updateTabSelected($event)"
        [companyInfo]="payload?.companyDetail">
        <div class="invoice-detail">
            <div class="boxes">
                <app-detail-box [label]="'Tasa anualizada'" [value]="payload?.tea + ' %'"></app-detail-box>
                <app-detail-box [label]="'Tasa mensual'" [value]="payload?.tem + ' %'"></app-detail-box>
                <app-detail-box [label]="'Fecha de cobro'" [value]="payload?.paymentDate | formatDate2">
                </app-detail-box>
                <app-detail-box [label]="'Códigos ('+ payload?.physicalInvoices?.length + ')'"
                    [top]="payload?.physicalInvoices?.length > 1 ? 18 : 25" [isCustomValue]="true"
                    [longValue]="payload?.physicalInvoices?.length > 1 ? codes : null">
                    <!-- [value]="payload?.physicalInvoices?.length > 1 ? payload?.physicalInvoices[0]?.code + '\n...' : payload?.physicalInvoices[0]?.code" -->
                    <div class="box-value">
                        {{payload?.physicalInvoices[0]?.code}}
                        <span *ngIf="payload?.physicalInvoices?.length > 1" style="font-weight: bold;">
                            <br>
                            ...
                        </span>
                    </div>
                </app-detail-box>
                <app-detail-box [label]="'Tipo de operación'" [isCustomValue]="true">
                    <div class="box-value">
                        <lib-operation-type [operationType]="payload?.isConfirming"></lib-operation-type>
                    </div>
                </app-detail-box>
            </div>
        </div>
        <div class="company-detail">
            <p class="company-detail-content">{{payload.companyDetail}}</p>
        </div>
        <div class="risk-detail">
            <ng-container>
                <div class="boxes">
                    <app-detail-box [label]="'Tipo de sociedad'" [value]="getRiskData(payload, 'companyName')">
                    </app-detail-box>
                    <app-detail-box [label]="'Fecha de creación'" [value]="getRiskData(payload, 'createdAt', 'others')"
                        [others]="getRiskData(payload, 'createdAt') ? getRiskData(payload, 'createdAt') + ' años' : ''"
                        [tooltip]="'La antigüedad de la empresa indica la experiencia que tiene en el negocio.'"
                        [top]="'18'">
                    </app-detail-box>
                    <app-detail-box [label]="'Padrón SUNAT'"
                        [value]="(getRiskData(payload, 'regimeType') == '0') ? '---'  : (getRiskData(payload, 'regimeType')  | capitalizeOnlyFirstLetter)"
                        [tooltip]="'Una empresa registrada en un padrón de SUNAT, como Agente de Percepción, Agente de Retención o Buen Contribuyente, cumple con sus obligaciones tributarias.'">
                    </app-detail-box>
                    <app-detail-box [label]="'Nro de trabajadores'" [value]="getRiskData(payload, 'employeeCount')"
                        [tooltip]="'Permite proyectar un flujo de efectivo mínimo para afrontar el pago de su planilla y estimar el tamaño de la empresa.'">
                    </app-detail-box>
                    <app-detail-box [label]="'Deuda directa'" [value]="getRiskData(payload, 'directDebt')"
                        [tooltip]="'Indica el nivel de confianza de bancos y financieras y la capacidad de recibir financiamiento.'">
                    </app-detail-box>
                    <app-detail-box [label]="'Deuda indirecta'" [value]="getRiskData(payload, 'indirectDebt')"
                        [tooltip]="'Indica qué tanto los bancos y financieras han garantizado el cumplimiento de las obligaciones de la empresa (cartas fianzas, cartas de crédito documentario de importación, etc).'">
                    </app-detail-box>
                    <app-detail-box [label]="'Garantías hipotecarias'"
                        [value]="getRiskData(payload, 'mortgageGuarantees')"
                        [tooltip]="'Indica los activos que respaldan las obligaciones financieras de la empresa. Permite estimar su nivel patrimonial o el de sus garantes.'">
                    </app-detail-box>
                    <app-detail-box [label]="'Protestos sin aclarar'" [value]="getRiskData(payload, 'unclearProtests')"
                        [tooltip]="'Permite conocer el comportamiento de la empresa frente a las obligaciones que ha asumido con títulos valores. Se observan las deudas impagas registradas durante los últimos 24 meses. El monto es relevante cuando representa un porcentaje alto de la deuda directa.'">
                    </app-detail-box>
                    <app-detail-box [label]="'Importaciones'" [value]="getAdditionalInfo(payload, 'importsValue')"
                        [others]="getAdditionalInfo(payload, 'importsOpsValue') ? getAdditionalInfo(payload, 'importsOpsValue') + ' operaciones': ''"
                        [tooltip]="'Indica el nivel de ventas de la empresa en el 2020 y que sus proveedores son del exterior.'"
                        [top]="'18'">
                    </app-detail-box>
                    <app-detail-box [label]="'Exportaciones'" [value]="getAdditionalInfo(payload, 'exportsValue')"
                        [others]="getAdditionalInfo(payload, 'exportsOpsValue') ? getAdditionalInfo(payload, 'exportsOpsValue') + ' operaciones': ''"
                        [tooltip]="'Indica el nivel de ventas de la empresa en el 2020 y cuánto de su mercado y cartera son del exterior.'"
                        [top]="'18'">
                    </app-detail-box>
                </div>

                <div class="icon-row2">
                    <img src="../../../../../../assets/icons/info.svg">
                    <span class="p-height">Fecha de consulta: {{payload?.evaluation?.createdAt | date: 'dd/MM/yyyy' }}.
                        Fuentes: Central
                        de Riesgos de la SBS, centrales privadas de
                        riesgos, Registro Nacional de Protestos y Moras de la Cámara de Comercio de Lima, SUNAT, entre
                        otras fuentes de información. <a class="link" target="_blank"
                            href="https://storage.googleapis.com/finsmart-websitefiles/legal/clasificacion-empresas.pdf">Más
                            información aquí</a>.</span>
                </div>
            </ng-container>
        </div>
        <div class="finsmart-history">
            <ng-container>
                <div class="boxes">
                    <app-detail-box [label]="'Facturas finalizadas'" [value]="debtorStats?.finalizedInvoicesCount">
                    </app-detail-box>
                    <app-detail-box [label]="'Facturas en curso'"
                        [value]="debtorStats?.invoicesAwaitingCollectionCount">
                    </app-detail-box>
                    <app-detail-box [label]="'En mora (> 8 días)'" [value]="debtorStats?.delayedInvoicesCount">
                    </app-detail-box>
                    <app-detail-box [label]="'Prom. mora con FS'"
                        [value]="debtorStats?.averageDelayOfFinalizedInvoices">
                    </app-detail-box>
                </div>
            </ng-container>
        </div>
    </lib-tab-invest-in-invoice>
    <div class="payer-description">

        <p class='body xxs text-dark-gray line-16'>Debes analizar los riesgos y condiciones aplicables a las operaciones
            de factoring o confirming según sus contratos. <a href="https://finsmart.pe/legal" target="_blank"
                class="hyperlink">Revísalos aquí.</a></p>
    </div>
</div>