<div class="header">
  <img src="../../../../../../assets/icons/business-24px.svg" alt="" />
  <h1>Cuenta Empresa</h1>
</div>
<p class="subTitle">Completa los siguientes datos:</p>

<form [formGroup]="form">
  <app-input
    formControlName="companyRuc"
    label="RUC"
    [hasIcon]="hasIcon"
    [iconClass]="iconName"
  ></app-input>
  <div *ngIf="iconName === 'alert-icon'" class="error-message">
    RUC no válido
  </div>
  <div class="controls">
    <app-select
      formControlName="channel"
      [options]="channels"
      valueField="value"
      textField="label"
      label="¿Cómo te enteraste de nosotros?"
    ></app-select>
  </div>

  <app-checkbox
    [content]="termConditionContent"
    formControlName="isNewsletter"
    color="accent"
  ></app-checkbox>

  <div class="acceptCondition">
    <p>
      Al hacer clic en <span class="bold">"Crear cuenta"</span> aceptas los
      <a class="bold blue" href="https://finsmart.pe/legal" target="_blank">
        Contratos de Empresa.</a
      >
    </p>
  </div>

  <div class="actionButtons center-dp">
    <fs-ui-button
      (click)="backward()"
      [label]="'Atrás'"
      [type]="'tertiary'"
    ></fs-ui-button>
    <fs-ui-button (click)="submit()" [label]="'Crear Cuenta'"></fs-ui-button>
  </div>
</form>
